<template>
  <div class="w-full">
    <Swiper
      :breakpoints="breakpoints"
      :space-between="20"
      class="relative items-stretch"
      @swiper="setSwiper"
    >
      <div
        class="pointer-events-none absolute right-0 top-0 z-40 hidden h-full w-1/6 bg-gradient-to-l from-10% to-transparent xs:block"
        :class="{
          [shadowClasses]: shadowClasses,
        }"
      />
      <SwiperButton
        v-if="arrowsVisible"
        direction="prev"
        class="absolute left-4 top-1/2 z-50 flex size-8 items-center justify-center bg-gray-500 text-white"
      />
      <SwiperButton
        v-if="arrowsVisible"
        direction="next"
        class="absolute right-4 top-1/2 z-50 flex size-8 items-center justify-center bg-gray-500 text-white"
      />
      <SwiperSlide v-for="product in products" :key="product.id">
        <ShopProductCard
          :key="product.id"
          :product="product"
          :white="white"
          class="h-full"
        />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script setup lang="ts">
import SwiperButton from '~/components/SwiperButton.vue'
import type { Schemas } from '#shopware'

const props = withDefaults(
  defineProps<{
    products: Schemas['Product'][]
    shadowClasses?: string
    size?: 'sm' | 'md' | 'lg'
    white?: boolean
  }>(),
  {
    size: 'sm',
    shadowClasses: 'from-gray-10 lg:hidden',
    white: true,
  }
)

const { width } = useWindowSize()
const swiperRef = ref()

const sizes = {
  sm: 4,
  md: 5,
  lg: 6,
}

const productCount = computed(() => props.products.length)

const breakpoints = computed(() => {
  return {
    0: {
      slidesPerView: 1,
      loop: productCount.value > 2,
    },
    448: {
      slidesPerView: productCount.value >= 2 ? 1.5 : 1,
      loop: productCount.value > 2,
    },
    640: {
      slidesPerView: 2,
      loop: productCount.value > 2,
    },
    768: {
      slidesPerView: 3,
      loop: productCount.value > 3,
    },
    1024: {
      slidesPerView: 2,
      loop: productCount.value > 2,
    },
    1280: {
      slidesPerView: 3,
      loop: productCount.value > 3,
    },
    1536: {
      slidesPerView: sizes[props.size],
      loop: productCount.value > sizes[props.size],
    },
  }
})

const arrowsVisible = computed(() => {
  if (width.value < 448) {
    return productCount.value > 1
  } else if (width.value < 768) {
    return productCount.value > 2
  } else if (width.value < 1024) {
    return productCount.value > 3
  } else if (width.value < 1280) {
    return productCount.value > 2
  } else if (width.value < 1536) {
    return productCount.value > 3
  } else {
    return productCount.value > sizes[props.size]
  }
})

const setSwiper = (swiper: any) => {
  swiperRef.value = swiper
}
</script>

<style scoped lang="postcss">
::v-deep(.swiper-slide) {
  @apply max-md:h-auto;
}
</style>
